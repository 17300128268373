import { useLocalStorage } from 'react-use';

const defaultSteps = {
  stepOne: {
    selectedPlan: {
      planId: '',
      planName: '',
      planValue: 0,
    },
    selectedCardIndex: null,
  },
  stepTwo: {
    fullName: '',
    email: '',
    phone: '',
    userType: 'Teacher',
    acceptedPrivacy: false,
    planName: '',
  },
  stepThree: {
    userName: '',
    birthdate: '',
    cnpj: '',
    acceptedTerms: false,
  },
};

export function useTeacherOnboarding() {
  const [teacherRegister, setTeacherRegister, removeTeacherRegister] = useLocalStorage('teacher-register', { ...defaultSteps });

  function setStepOne(data) {
    setTeacherRegister({
      ...teacherRegister,
      stepOne: {
        ...data,
      },
    });
  }

  function setStepTwo(data) {
    setTeacherRegister({
      ...teacherRegister,
      stepTwo: {
        ...data,
      },
    });
  }

  function setStepThree(data) {
    setTeacherRegister({
      ...teacherRegister,
      stepThree: {
        ...data,
      },
    });
  }

  function resetSteps() {
    setTeacherRegister({ ...defaultSteps });
  }

  return {
    setStepOne,
    setStepTwo,
    setStepThree,
    teacherRegister,
    resetSteps,
  };
}
